body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    background-color:  '#ededed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.queryHeader {
  font-family : 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif,
  ;
  font-weight: bolder;
  background-color:rgb(231, 235, 238);
  font-size:smaller
 

}
.piePointer{
  cursor: pointer;

}
.piePointer:hover{
  cursor: pointer;
}
.queryRow   {background-color:rgb(248, 252, 248); font-size: 1; }
.queryRowFinal {background-color:rgb(199, 203, 207);color:darkgreen;border:2px} 
.queryRow.Critical {background-color: hotpink; font-size: 50px;}
.queryHeader:hover{
  background-color: yellowgreen;
  cursor:  n-resize;   font-size: 10;
 
}
.queryRow:hover {
 
  cursor:  pointer; size:20;
  font-size: 1;
}
.centerMsg {
  padding: 200px 0;
  color: lightgrey;
  font-size: larger; 
  text-align: center;
}
.queryRowSel{background-color: cornflowerblue; font-weight: bolder; font-size:100; font-size-adjust: initial;}
.cc_table{
  width:100%;border-width: 0px; border-color: rgba(104, 102, 102, 0.37); border-style: none; font-size: 12pt;
}
.cc_table_ur{
  width:100%;border-width: 2
  0px; border-color: rgba(104, 102, 102, 0.37); border-style: none; font-size: 10pt;
}
.panelHeaderSm{
  background-color: linen; font-display: inherit; font-size: 12pt; color:rgba(104, 102, 102, 0.37);
}
.panelHeader{
  background-color: linen; font-display: inherit; font-size: 12pt; font-weight: bold; color:rgba(104, 102, 102, 0.37);fill-rule: nonzero;  
}
.compliance_concern_box{
   font-size: larger; font-weight: bold;
}
.compliance_concern_box.field{
  font-weight: bolder;
}
.critical  {
    background-color:rgb(247, 220, 170); width: 100%;font-size: larger; font-weight: bold;
}
.queryRowLow{
  font-weight: 400; font-size: 10pt;
}
.pairRow:hover{
  background-color: rgb(199, 203, 207);cursor: pointer;
}
.queryRowCritical{
    color:red ;font-weight: 600;font-size: 10pt
} 
.queryRowModerate{
  color:rgb(65, 22, 22) ; font-weight: 500;font-size: 10pt;
}
.details_header{font-size: 24px; font-weight: bold; background-color: royalblue;border-radius: 8; color:whitesmoke;width:100%;}
.details_field{font-weight: bold;}

.leafletPop {width: 450px;}
.cc_Image {width:500px; height:400px; padding: 20px; }
.lPop {width:700px; height: 1200px;  }

a { text-decoration: none; color:inherit}
a:hover{color:teal;  }

.DownloadButton {width:25px}

.bannerIcon {width:75px;margin-left: -200; position: relative; background-position-x: left; image-rendering: optimizeQuality;}

.homeImage {
  /* The image used */
  background-image: url('https://o.aolcdn.com/images/dims?quality=100&image_uri=https%3A%2F%2Fs.aolcdn.com%2Fhss%2Fstorage%2Fmidas%2F4d8959c2b43a0c11f0063b9d82543bf9%2F204910826%2FRTR34NYA-ed.jpg&client=amp-blogside-v2&signature=6b779fec5bb287d7a2548f00a7ecae41a7ef1cb8');

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.details_field {font-size : 12px; font-weight: bold; }
.details_field_text {font-size : 12px }
img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  opacity: 0.2;;
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.reportCell {
  vertical-align: top;background-color:wn; align-items: center;
} 
.pdf{
 overflow: scroll;
 display: block;
 max-height:800;   max-width: 900;  width: 900;
}
.rptTable { 
  vertical-align: top;
}
.rptTable.td{
  vertical-align: top; 
}
.dailyTable {
  position: sticky; padding: 0px; box-shadow: salmon;  border-width: 0px; font-size: 10pt; border-color: rgba(104, 102, 102, 0.37); border-style: none; top:135; position: -webkit-sticky;  
}
.filterBar{
  border-color: rgba(50, 45, 63, 0.027); background: none;; padding: 0; margin: 0; background-color: rgba(244, 165, 96, 0.062); 
}
.dailyTable.as{
  border-color: rgba(255, 97, 49, 0.37);font-weight: bolder;
}
.mapDialog {
  background-color: rgba(35, 35, 36, 0.808);color: rgba(226, 100, 15, 0.918);font-style: normal; font-weight: bold;
}
.ccDialog{
  maxWidth: 600;
}

.homeTitle {
  font-size: 72pt;
  margin: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); color:rgba(104, 102, 102, 0.8); font-family:'Lato';   ;
}
.homeText{
  font-size: 24pt;
  margin: 0;
  position: absolute;text-shadow: lightslategray;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); color:rgba(104, 102, 102, 0.8)
}

.ccGroupIcon{
  font-size: 72; color:saddlebrown; size: 500; 
}